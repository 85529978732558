<template>
  <div class="container-fluid mt-3">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template v-slot:header>
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">{{$t("listSurveyItem.mySurveysList")}}</h3>
              <!-- <small class="text-muted">
                Showing {{ to }} of {{ total }} results

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </small> -->
            </div>
            <div class="col-6 text-right">
              <base-button type="primary" icon size="sm" @click="addSurvey()">
                <span class="btn-inner--icon"
                  ><i class="ni ni-fat-add"></i
                ></span>
                <span class="btn-inner--text">{{$t("listSurveyItem.newSurvey")}}</span>
              </base-button>
            </div>
          </div>
        </template>

        <template>
          <div class="d-flex justify-content-center flex-wrap">
            <div class="col-10">
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                :placeholder="$t('listSurveyItem.typeNameToSearch')+'...'"
                clearable
              />
            </div>
          </div>
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="surveys"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
              :label="$t('listSurveyItem.name')"
              prop="name"
              min-width="200px"
              sortable="custom"
            />
            <el-table-column
              :label="$t('listSurveyItem.color')"
              prop="color"
              min-width="200px"
              sortable="custom"
            >
              <template v-slot="{ row }">
                <span
                  class="badge badge-default"
                  :style="{ backgroundColor: row.color }"
                  >{{ row.name }}</span
                >
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('listSurveyItem.createdAt')"
              min-width="200px"
              prop="created_at"
              sortable
            >
              <template v-slot="{ row }">{{
                row.created_at | humanTime
              }}</template>
            </el-table-column>
            <el-table-column
              :label="$t('listSurveyItem.actions')"
              class-name="actions"
              min-width="120px"
              align="center"
            >
              <template v-slot="{ row }">
                <div class="table-actions">
                  <el-tooltip :content="$t('listSurveyItem.completeSurvey')" placement="top">
                    <a
                      type="text"
                      @click="completeSurvey(row)"
                      class="table-action"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="far fa-eye text-base"></i>
                    </a>
                  </el-tooltip>
                  <el-tooltip :content="$t('listSurveyItem.editSurvey')" placement="top">
                    <a
                      type="text"
                      @click="editSurvey(row)"
                      class="table-action text-base"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="far fa-edit"></i>
                    </a>
                  </el-tooltip>

                  <el-tooltip :content="$t('listSurveyItem.deleteSurvey')" placement="top">
                    <a
                      type="text"
                      @click="deleteSurvey(row.id)"
                      class="table-action table-action-delete text-base"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>

        <template v-slot:footer>
          <div
            class="row d-flex justify-content-md-between justify-content-center flex-wrap"
          >
            <div class="col-auto">
              <el-select
                class="select-primary pagination-select mb-1"
                style="width: 140px"
                v-model="pagination.perPage"
                placeholder="Per page"
              >
                <el-option
                  class="select-primary mt-1"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>

            <div class="col-auto">
              <base-pagination
                class="pagination-no-border"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
              />
            </div>
          </div>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Drawer,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import swal from "sweetalert2";

export default {
  layout: "Survey List",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Drawer.name]: Drawer,
  },

  data() {
    return {
      drawer: false,
      query: null,
      surveys: [],
      selectedRows: [],
      sort: "created_at",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
      loading: true,
    };
  },

  computed: {
    user() {
      return this.$store.getters["profile/me"];
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "fetchListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "fetchList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    fetchListDebounced: _.debounce(function () {
      this.fetchList();
    }, 300),

    async fetchList() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
          ...(this.query ? { name: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
        include: "user",
      };
      // fire a GET request to load the items
      await this.$store.dispatch("surveys/list", params).then(() => {
        this.surveys = this.$store.getters["surveys/list"];
        this.total = this.$store.getters["surveys/listTotal"];
      });
      this.loading = false;
    },
    addSurvey() {
      this.$router.push({ name: "Add Survey" });
    },
    editSurvey(row) {
      this.$router.push({
        name: "Edit Survey",
        params: { id: row.id },
      });
    },
    completeSurvey(row) {
      this.$router.push({
        name: "Complete Survey",
        params: { id: row.id },
      });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.fetchList();
    },
    async deleteSurvey(id) {
      try {
        const confirmation = await swal.fire({
          title: this.$t('listSurveyItem.deleteThisSurvey'),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t('listSurveyItem.yesDeleteIt') + '!',
          cancelButtonText: this.$t('listSurveyItem.noKeepIt'),
          confirmButtonClass: "btn btn-warning",
          cancelButtonClass: "btn btn-primary",
        });

        if (confirmation.value === true) {
          // send DELETE request to remove the survey
          await this.$store.dispatch("surveys/destroy", id).then(
            () => this.fetchList() // fetch to refresh the remaining survey list
          );
          this.$notify({
            type: "success",
            message: this.$t('listSurveyItem.surveyDeletedSuccessfully')+'.',
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message: this.$t('listSurveyItem.thisSurveyStillHasAssociatedItem')+'.',
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t('general.oopsSomethingWentWrong') + '!',
          });
        }
      }
    },
  },
};
</script>
