<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />
    <div class="row mt-3">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{$t("listSurveyItem.addSurvey")}}</h3>
            </div>
          </div>
          <div id="surveyCreator" />
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import BackButton from "@/components/BackButton.vue";
import { SurveyCreator } from "survey-creator-knockout";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
};
export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    BackButton,
  },
  data() {
    return {
      survey: {},
    };
  },
  mounted() {
    const creator = new SurveyCreator(creatorOptions);
    creator.saveSurveyFunc = (saveNo, callback) => {
      // If you use a web service:
      this.saveSurveyJson(creator.JSON, saveNo);
    };
    creator;
    creator.render("surveyCreator");
  },
  methods: {
    goBack() {
      this.$router.push({ name: "List Surveys" });
    },
    async saveSurveyJson(json, saveNo) {
      json.type = "surveys";
      if (!this.survey.id) {
        await this.$store.dispatch("surveys/add", json).then(() => {
          this.survey = this.$store.getters["surveys/survey"];
        });
      } else {
        json.id = this.survey.id;
        await this.$store.dispatch("surveys/update", json);
      }
    },
  },
};
</script>
