<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />

    <div class="row mt-3">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{$t("editVenueItem.editVenue")}}</h3>
            </div>
            <!-- <div class="col-4 text-right">
              <base-button
                @click="goBack"
                type="button"
                class="btn btn-sm btn-primary"
                >Back to list</base-button
              >
            </div> -->
          </div>
          <div class="card-body">
            <form ref="profile_form" @submit.prevent="handleSubmit">
              <base-input
                :label="$t('editVenueItem.name')"
                prepend-icon="fas fa-user"
                v-model="venue.name"
              />
              <validation-error :errors="apiValidationErrors.name" />

              <base-mapbox
                @getCoordinatesValues="getCoordinates($event)"
                :long="venue.long"
                :lat="venue.lat"
                :label="$t('editVenueItem.venueLocation')"
                v-if="venue.long && venue.lat"
              ></base-mapbox>
              <div class="my-4">
                <base-button
                  type="button"
                  class="btn btn-sm btn-primary"
                  native-type="submit"
                >
                  {{$t("editVenueItem.updateVenue")}}
                </base-button>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import BackButton from "@/components/BackButton.vue";
import BaseMapbox from "@/components/Mapbox/BaseMapbox.vue";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    ValidationError,
    BackButton,
    BaseMapbox,
  },

  data() {
    return {
      venue: {
        type: "venues",
        name: null,
      },
    };
  },
  created() {
    this.get();
  },
  methods: {
    async get() {
      try {
        const id = this.$route.params.id;
        let params = {
          filter: {
            ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
          },
        };

        await this.$store
          .dispatch("venues/get", { id, params })
          .then(() => (this.venue = this.$store.getters["venues/venue"]));
      } catch (error) {
        this.$notify({
          type: "danger",
         message: this.$t('general.oopsSomethingWentWrong')+ '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    async handleSubmit() {
      if (this.$isDemo == 1 && ["1", "2"].includes(this.$route.params.id)) {
        this.$notify({
          type: "danger",
          message: this.$t('editVenueItem.youAreNotAllowedNotChangeDataOfDefaultVenues')+'.',
        });
        return;
      }
      try {
        await this.$store.dispatch("venues/update", this.venue);

        this.$notify({
          type: "success",
          message: this.$t('editVenueItem.venueUpdatedSuccessfully')+'.',
        });
        this.goBack();
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong') + '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
    goBack() {
      this.$router.push({ name: "List Venues" });
    },
    getCoordinates(values) {
      if (values) {
        this.venue.long = values.center[0].toString();
        this.venue.lat = values.center[1].toString();
        this.venue.address_name = values.address;
      }
    },
  },
};
</script>
