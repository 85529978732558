<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />
    <div class="row mt-3">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{$t("addTag.addTag")}}</h3>
            </div>        
          </div>
          <add-tag @tagAdded="goBack($event)"></add-tag>         
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import AddTag from "@/components/AddTag/AddTag.vue";
import BackButton from "@/components/BackButton.vue";
export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    AddTag,
    BackButton
  },
  methods: {
    goBack() {
      this.$router.push({ name: "List Tags" });
    }
  }
};
</script>
