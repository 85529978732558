<template>
  <div class="container-fluid mt-3">
    <div class="help-container mb-1">
      <el-drawer
        title="I am the title"
        :visible.sync="drawer"
        :with-header="false"
      >
        <h4 class="mt-5 mb-3">Visual Guide:</h4>
        <vue-plyr>
          <div
              data-plyr-provider="youtube"
              data-plyr-embed-id="-B58GgsehKQ"
          />
        </vue-plyr>
        <hr>
        <h4>Quick Guide:</h4>
        <ul><li>Follow BookMeInScotty on:
          <a href="#">Twitter</a>.</li> <li>Read and subscribe to our newsletter
          <a href="#">Book Me In Scotty Blog</a>.</li> <li>Follow Book Me In Scotty on
          <a href="#">Instagram</a>.</li> <li>Follow Me In Scotty on
          <a href="https://www.facebook.com/creativetim">Facebook</a>.</li></ul>
        <hr>

        <h4> Help Center: </h4> <a href="#">Read the official documentation here</a>
      </el-drawer>
    </div>

    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <!-- Include Header-->
        <template v-slot:header>
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">{{$t("listTicketItem.myTicketsList")}}
<!--                <i class="fa fa-info-circle pointer-class" @click="drawer = true"/>-->
              </h3>
              <small class="text-muted">
           {{$t("general.showing")}} {{ to }} {{$t("general.of")}}  {{ total }}  {{$t("general.results")}} 

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </small>
            </div>
            <div class="col-6 text-right">
              <base-button type="primary" icon size="sm" @click="addTicket()">
                <span class="btn-inner--icon"
                  ><i class="ni ni-fat-add"></i
                ></span>
                <span class="btn-inner--text">{{$t("listTicketItem.newTicket")}}</span>
              </base-button>
            </div>
          </div>
        </template>

        <template>
          <div class="d-flex justify-content-center flex-wrap">
            <div class="col-10">
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                :placeholder="$t('general.select')+'...'"
                clearable
              />
            </div>
          </div>
          <el-table
            class="table-responsive align-items-center table-flush"
            header-row-class-name="thead-light"
            :data="tickets"
            @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column :label="$t('listTicketItem.name')"
              prop="name"
              min-width="200px"
              sortable
            />

            <el-table-column :label="$t('listTicketItem.description')"
              prop="description"
              min-width="200px"
              sortable
            />
            <!-- <el-table-column label="Total" prop="total_quantity" min-width="200px" sortable /> -->
            <el-table-column :label="$t('listTicketItem.createdAt')"
              prop="created_at"
              min-width="200px"
              sortable
            >
              <template v-slot="{ row }">
                {{ row.created_at | humanTime }}
              </template>
            </el-table-column>

            <el-table-column :label="$t('listTicketItem.updatedAt')"
              prop="updated_at"
              min-width="200px"
              sortable
            >
              <template v-slot="{ row }">
                {{ row.updated_at | humanTime }}
              </template>
            </el-table-column>

            <el-table-column :label="$t('listTicketItem.actions')"
              class-name="actions"
              min-width="120px"
              align="center"
            >
              <template v-slot="scope">
                <div class="table-actions">
                  <el-tooltip :content="$t('listTicketItem.editTicket')" placement="top">
                    <a
                      type="text"
                      @click="editTicket(scope.row)"
                      class="table-action text-base"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="far fa-edit"></i>
                    </a>
                  </el-tooltip>

                  <el-tooltip :content="$t('listTicketItem.deleteTicket')" placement="top">
                    <a
                      type="text"
                      @click="deleteTicket(scope.row.id)"
                      class="table-action table-action-delete text-base"
                      data-toggle="tooltip"
                      style="cursor: pointer"
                    >
                      <i class="far fa-trash-alt"></i>
                    </a>
                  </el-tooltip>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </template>

        <template v-slot:footer>
          <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
          </div>
        </template>
      </card>
    </div>
  </div>
</template>
<script>
import { BasePagination } from "@/components/";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Drawer,
  Button,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    BasePagination,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Drawer.name]: Drawer
  },

  data() {
    return {
      drawer: false,
      tickets: [],
      selectedRows: [],
      query: null,
      sort: "-created_at",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
      },

      total: 0,
      loading: true,
    };
  },

  computed: {
    user() {
      return this.$store.getters["profile/me"];
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function() {
      this.getList();
    }, 300),

    async getList() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...(this.user ? { user_id: this.user.id } : { user_id: 0 }),
          ...(this.query ? { name: this.query } : {}),
          ...(this.query ? { description: this.query } : {}),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };

      await this.$store.dispatch("tickets/list", params).then(() => {
        this.tickets = this.$store.getters["tickets/list"];
        this.total = this.$store.getters["tickets/listTotal"];
      });
      this.loading = false;
    },

    async deleteTicket(id) {
      try {
        const confirmation = await swal.fire({
          title: this.$t('listTicketItem.deleteThisTicket'),
          type: "question",
          buttonsStyling: false,
          showCancelButton: true,
          confirmButtonText: this.$t('listTicketItem.yesDeleteIt')+ '!',
          cancelButtonText: this.$t('listTicketItem.noKeepIt'),
          confirmButtonClass: "btn btn-warning",
          cancelButtonClass: "btn btn-primary",
        });

        if (confirmation.value === true) {
          await this.$store.dispatch("tickets/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message:this.$t('listTicketItem.ticketDeletedSuccessfully')+'.',
          });
        }
      } catch (error) {
        if (error.response.data.errors[0]) {
          this.$notify({
            type: "danger",
            message:this.$t('listTicketItem.thisTicketStillHasAssociatedItems')+'.',
          });
        } else {
          this.$notify({
            type: "danger",
            message: this.$t('general.oopsSomethingWentWrong') + '!',
          });
        }
      }
    },
    addTicket() {
      this.$router.push({ name: "Add Ticket" });
    },
    editTicket(row) {
      this.$router.push({
        name: "Edit Ticket",
        params: { id: row.id },
      });
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    }
  },
};
</script>
