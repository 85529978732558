<template>
  <div class="container-fluid mt-3">
    <back-button @click="goBack" />

    <div class="row mt-3">
      <div class="col-xl-12 order-xl-1">
        <card>
          <div slot="header" class="row align-items-center">
            <div class="col-8">
              <h3 class="mb-0">{{$t("addTicket.newTicket")}}</h3>
            </div>           
          </div>
          <add-ticket @ticketAdded="goBack($event)"></add-ticket>          
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import formMixin from "@/mixins/form-mixin";
import BackButton from "@/components/BackButton.vue";
import AddTicket from "@/components/AddTicket/AddTicket.vue";

export default {
  layout: "DashboardLayout",

  mixins: [formMixin],

  components: {
    BackButton,
    AddTicket
  },

  data() {
    return {
      ticket: {
        type: "tickets",
        name: null,
        description: null,
      },
    };
  },
  methods: {
    goBack() {
      this.$router.push({ name: "List Tickets" });
    }
  }
};
</script>
