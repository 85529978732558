<template>
  <div class="container-fluid mt-3">
    <div class="help-container mb-1">
      <el-drawer
        title="I am the title"
        :visible.sync="drawer"
        :with-header="false"
      >
        <h4 class="mt-5 mb-3">Visual Guide:</h4>
        <hr />
        <h4>Quick Guide:</h4>
        <ul>
          <li>Follow BookMeInScotty on: <a href="#">Twitter</a>.</li>
          <li>
            Read and subscribe to our newsletter
            <a href="#">Book Me In Scotty Blog</a>.
          </li>
          <li>Follow Book Me In Scotty on <a href="#">Instagram</a>.</li>
          <li>
            Follow Me In Scotty on
            <a href="https://www.facebook.com/creativetim">Facebook</a>.
          </li>
        </ul>
        <hr />

        <h4>Help Center:</h4>
        <a href="#">Read the official documentation here</a>
      </el-drawer>
    </div>
    <!--  Main Page -->
    <div>
      <div class="row">
        <div class="col-xl-4 col-md-4">
          <base-stats-card
              :title="$t('listTickets.totalTicketOrders')"
              type="gradient-base"
              :sub-title="stats.totalBookings"
              :loading="loading"
              icon="ni ni-chart-pie-35"
          >
          </base-stats-card>
        </div>

        <div class="col-xl-4 col-md-4">
          <base-stats-card
            :title="$t('listTickets.totalAmountPaid')"
            type="gradient-info"
            :sub-title="stats.totalSales"
            :loading="loading"
            icon="ni ni-money-coins"
          >
          </base-stats-card>
        </div>

        <div class="col-xl-4 col-md-4">
          <base-stats-card
              :title="$t('listTickets.averageTicketPrice')"
              type="gradient-red"
              :sub-title="stats.averagePrice"
              :loading="loading"
              icon="fa fa-users"
          >
          </base-stats-card>
        </div>
      </div>

      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">{{$t("listTickets.myTicketCollection")}}</h3>
              <small class="text-muted">
             {{$t("general.showing")}} {{ to }} {{$t("general.of")}} {{ total }} {{$t("general.results")}}

                <span v-if="selectedRows.length">
                  &nbsp; &nbsp; {{ selectedRows.length }} rows selected
                </span>
              </small>
            </div>
            <div class="col-6 text-right button-dropdown">
              <el-dropdown
                trigger="click"
                class="dropdown btn base-button btn-base btn-icon btn-icon btn-fab btn-primary btn-sm"
                :disabled="attendees.length === 0"
              >
                <span class="btn-inner--icon">
                  <span class="mr-1 ml-2 text-white">{{$t("listTickets.download")}}</span>
                  <i class="fa fa-download text-white mt-0" />
                </span>

                <el-dropdown-menu
                  class="dropdown-menu dropdown-menu-arrow show text-center"
                  slot="dropdown"
                >
                  <div style="display: flex; flex-direction: column">
                    <base-button
                      type="primary"
                      icon
                      size="sm"
                      class="btn-base btn-icon"
                      @click="dataExport('csv')"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fa fa-download"></i
                      ></span>
                      <span class="btn-inner--text">{{$t("listTickets.asCsvFile")}}</span>
                    </base-button>

                    <base-button
                      type="primary"
                      icon
                      size="sm"
                      class="btn-base btn-icon"
                      @click="dataExport('excel')"
                    >
                      <span class="btn-inner--icon"
                        ><i class="fa fa-download"></i
                      ></span>
                      <span class="btn-inner--text">{{$t("listTickets.asExcelFile")}}</span>
                    </base-button>
                  </div>
                </el-dropdown-menu>

              </el-dropdown>
            </div>
          </div>
        </template>
        <div>
          <div class="d-flex justify-content-center flex-wrap">
            <div class="col-10">
              <base-input
                v-model="query"
                type="search"
                prepend-icon="fas fa-search"
                :placeholder="$t('listTickets.typeBookingNumberToSearch')"
                clearable
              />
            </div>
          </div>

          <el-table
              class="table-responsive align-items-center table-flush"
              header-row-class-name="thead-light"
              :data="attendees"
              @sort-change="sortChange"
          >
            <div slot="empty" v-if="loading">
              <img src="/img/loading.gif" style="height: 100px; width: 100px" />
            </div>

            <el-table-column
                type="expand"
            >
              <template v-slot="{ row }">
                <div v-if="row.special_requirements" class="mt-2 ml-5">
                  Special requirements :
                  <strong> {{ row.special_requirements }}</strong>
                </div>
                <div v-else class="mt-2 ml-5">
                  Special requirements : <strong> - </strong>
                </div>

                <hr>
                <!-- PDF Viewer -->
                <base-stats-card
                    :title="$t('listTickets.totalTicketOrders')"
                    type="gradient-base"
                >
                  <pdf :src="row?.ticket_document" :page="1"/>
                </base-stats-card>
              </template>
            </el-table-column>

            <el-table-column
                :label="$t('listTickets.bookingNo')"
                prop="ticket_number"
                min-width="160px"
                sortable
            >
              <template v-slot="{ row }">
                <strong>{{ row.ticket_number }}</strong>
              </template>
            </el-table-column>

            <el-table-column
                :label="$t('listTickets.name')"
                prop="name"
                min-width="175px"
                sortable
            />

            <el-table-column
                :label="$t('listTickets.qty')"
                prop="quantity"
                min-width="117px"
                sortable
            />

            <el-table-column
                :label="$t('listTickets.paid')"
                prop="price"
                min-width="144px"
                sortable
            >
              <template v-slot="{ row }">
                <span class="text-center">
                  {{ getTotalBookingAmount(row) }}
                </span>
              </template>
            </el-table-column>

            <el-table-column :label="$t('listTickets.payment')" min-width="150px">
              <template v-slot="{ row }">
                <badge class="badge-dot mr-4" type="">
                  <i
                      :class="`bg-${
                      row.is_paid === 1
                        ? 'success'
                        : row.is_paid === -1
                        ? 'warning'
                        : 'danger'
                    }`"
                  ></i>
                  <span class="status">{{
                      row.is_paid === 1
                          ? $t('listTickets.completed')
                          : row.is_paid === -1
                              ? $t('listTickets.pending')
                              : $t('listTickets.cancelled')
                    }}</span>
                </badge>
              </template>
            </el-table-column>

            <el-table-column
                :label="$t('listTickets.bookedAt')"
                min-width="200px"
                prop="created_at"
                sortable
            >
              <template v-slot="{ row }">
                {{ row.created_at | humanFullTimeYear }}
              </template>
            </el-table-column>

            <el-table-column
                :label="$t('listTickets.actions')"
                min-width="120px"
                prop="created_at"
                sortable
            >
              <template v-slot="{ row }">
                <div class="mr-5">
                  <el-tooltip :content="$t('listTickets.downloadTicketAsPdf')" placement="top">
                    <a :href="row.ticket_document" download>
                      <i class="fa fa-download text-base"></i>
                    </a>
                  </el-tooltip>
                </div>
              </template>

            </el-table-column>

          </el-table>

          <!--          <el-tabs class="mt-1 ml-3 mr-3" v-model="activeTab">-->
<!--            &lt;!&ndash; Details &ndash;&gt;-->
<!--            <el-tab-pane name="upcoming" @click="activeTab = 'upcoming'">-->
<!--                <span slot="label">-->
<!--                  <i class="ni ni-compass-04 mr-1"/>-->
<!--                  Upcoming-->
<!--                </span>-->

<!--            </el-tab-pane>-->
<!--            <el-tab-pane name="past" @click="activeTab = 'past'">-->
<!--                <span slot="label">-->
<!--                  <i class="ni ni-archive-2 mr-1"/>-->
<!--                  Past-->
<!--                </span>-->
<!--            </el-tab-pane>-->
<!--          </el-tabs>-->
        </div>
        <div
          v-if="firstRQDone"
          slot="footer"
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            placeholder="Per page"
            :disabled="attendees.length === 0"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          />
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Tabs,
  TabPane,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
  Empty,
  Drawer,
} from "element-ui";

import pdf from 'vue-pdf';
import { exportCsv } from "@/util/export-csv";
import { exportExcel } from "@/util/export-excel";
import "sweetalert2/dist/sweetalert2.css";

export default {
  layout: "DashboardLayout",

  components: {
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    [Empty.name]: Empty,
    [Drawer.name]: Drawer,
    pdf
  },

  data() {
    return {
      drawer: false,
      activeTab: "upcoming",
      attendees: [],
      events: [],
      selectedRows: [],
      query: null,
      sort: "created_at",
      firstRQDone: false,
      total: 0,
      stats: {
        totalBookings: "0",
        totalSales: "0",
        totalGuests: "0",
        averagePrice: "0",
      },
      allAttendees: [],
      loading: true,
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [25, 50, 100],
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["profile/me"];
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },
  watch: {
    query: {
      handler: "fetchListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "fetchList",
      immediate: false,
      deep: true,
    },
    attendees: {
      handler: "updateStats",
      immediate: false,
      deep: true,
    },
  },
  mounted() {
    this.updateStats();
    this.fetchListDebounced();
  },
  beforeDestroy() {
    // Check if the current route already has query parameters
    const hasQueryParameters = Object.keys(this.$route.query).length > 0;

    // If there are query parameters, navigate to the same route with null query
    if (hasQueryParameters) {
      this.$router.replace({ query: null });
    }
  },
  methods: {
    fetchListDebounced: _.debounce(function () {
      this.loading = true;
      this.fetchList();
    }, 300),
    async fetchList() {
      let params = {
        ...(this.sort ? { sort: this.sort } : {}),
        filter: {
          ...{ user_id: 0 }, // Do not remove
          ...( this.query ? { ticket_number: this.query } : {} ),
          ...( this.query ? { name: this.query } : {} ),
        },
        page: {
          number: this.pagination.currentPage,
          size: this.pagination.perPage,
        },
      };

      await this.$store.dispatch("guests/list", params).then(() => {
        this.attendees = this.$store.getters["guests/list"];
        this.total = this.$store.getters["guests/listTotal"];
        this.firstRQDone = true;
      });

      this.loading = false;
    },
    async updateStats() {
      this.loading = true;

      let params = {
        filter: {
          ...{ user_id: 0 },
          ...(this.query ? { ticket_number: this.query } : {}),
          ...( this.query ? { name: this.query } : {} ),
        },
      };
      await this.$store.dispatch("guests/list", params).then(() => {
        this.allAttendees = this.$store.getters["guests/list"];
      });

      // compute stats
      let sumBookings = 0;
      let averagePrice = 0;
      let sumSales = 0;
      this.allAttendees.forEach((attendee) => {
        sumBookings++;
        sumSales += attendee.price * attendee.quantity;
      });

      this.stats.totalBookings = sumBookings.toString();
      this.stats.totalSales = "AU$ " + sumSales.toString();

      averagePrice = sumSales / sumBookings;
      this.stats.averagePrice = "AU$ " + averagePrice.toString();

      this.loading = false;
    },
    async dataExport(fileType) {
      try {
        let params = {
          filter: {
            ...{ user_id: 0 },
            ...(this.query ? { ticket_number: this.query } : {}),
          },
        };

        await this.$store.dispatch("guests/list", params).then(() => {
          this.allAttendees = this.$store.getters["guests/list"];
        });

        const exportData = [];
        this.allAttendees.forEach((attendee) => {
          exportData.push({
            [this.$t('listTickets.fullName')]: attendee.name,
            [this.$t('listTickets.bookingNumber')]: attendee.ticket_number,
            [this.$t('listTickets.specialOrDietaryRequirements')]: attendee.special_requirements,
            [this.$t('listTickets.paid')]: attendee.is_paid === -1 ? this.$t('listTickets.no') : this.$t('listTickets.yes'),
            [this.$t('listTickets.confirmed')]: attendee?.is_confirmed === -1 ? this.$t('listTickets.pending') : this.$t('listTickets.yes'),
            [this.$t('listTickets.bookingStatus')]: attendee.is_paid === 1 ? this.$t('listTickets.completed') : this.$t('listTickets.pending'),
            [this.$t('listTickets.discount')]: attendee?.has_discount ? attendee?.price - attendee?.final_price : this.$t('listTickets.no'),
            [this.$t('listTickets.price')]: attendee?.final_price ?? attendee?.price,
            [this.$t('listTickets.quantity')]: attendee.quantity,
            [this.$t('listTickets.bookedAT"')]: attendee.created_at,
            // "EVENT ID": attendee.item_id,
          });
        });
        if (fileType === "csv") {
          exportCsv(exportData, "bookmeinscotty-guest-list.csv");
        } else {
          exportExcel(exportData, "bookmeinscotty-guest-list.xlsx");
        }
        this.loading = false;
      } catch (error) {
        console.log("Error", error);
        this.loading = false;
      }
    },
    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.fetchList();
    },
    getTotalBookingAmount(booking) {
      return '$ ' + booking?.price * booking?.quantity;
    },
  },
};
</script>
<style lang="scss">
.el-tabs__header {
  padding: 5px 15px 10px 15px;
  margin-bottom: 20px;
  border: 1px solid #f5f6f8;
  border-radius: calc(0.75rem - 1px);
}

.el-tabs__item {
  color: #525f7f;
  font-size: 0.875rem;
  font-weight: 600;
}

.el-tabs__item.is-active {
  color: #5e72e4ff;
}
.el-tabs__active-bar {
  background-color: #5e72e4ff !important;
}
</style>

<style scoped>
.el-table_1_column_1.undefined.el-table__expand-column.el-table__cell {
  th {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.el-tabs--card >>> .el-tabs__header {
  box-shadow: 0 0 0 0 !important;
  border: 0 !important;
  padding-right: 0;
  padding-left: 0;
}

.el-tabs__header >>> el-tabs__new-tab {
  color: #5e72e4ff !important;
}
</style>
