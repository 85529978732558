<template>
  <div class="container-fluid mt-4">
    <base-attendee-ticket :ticket="ticket"></base-attendee-ticket>
  </div>
</template>

<script>
import {
  Option,
  Select,
  Image,
  Carousel,
  CarouselItem,
  Skeleton,
  SkeletonItem,
} from "element-ui";
import swal from "sweetalert2";
import BaseAttendeeTicket from "@/components/Ticket/BaseAttendeeTicket.vue";


export default { 
  name: "PublicTicketPage",
  components: {
    BaseAttendeeTicket
  },
  data() {
    return {
      ticket: { id: null },
    };
  },
  created() {
    this.fetchTicket();
  },
  computed: {
    logo() {
      return process.env.VUE_APP_DEFAULT_LOGO;
    },
  },
  methods: {
    async fetchTicket() {
      try {
        const id = (this.ticket.id = this.$route.params.id);
        let params = {
          //include: "category,tags,tickets,venues",
        };
        await this.$store
          .dispatch("attendees/getPublic", { id, params })
          .then(
            () => (this.ticket = this.$store.getters["attendees/attendee"])
          );
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t('general.oopsSomethingWentWrong')+ '!',
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
